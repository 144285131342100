import React from 'react'
import { graphql } from 'gatsby'
import img404 from '../img/404.svg'

const NotFoundPage = () => (
  <div className="flex h-screen items-center justify-center">
    <div>
      <img src={img404} alt="Page not found." />
    </div>
  </div>
)

export default NotFoundPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
